import { gql, useQuery } from "@apollo/client";
import { GetStaffQuery, GetStaffQueryVariables } from "../../schema";
import RichTextRender from "../RichTextRender/RichTextRender";

export default function Staff() {
  const query = gql`
    query getStaff {
      staffMemberCollection {
        items {
          name
          description {
            json
          }
          order
          image {
            url
          }
        }
      }
    }
  `;

  const { data } = useQuery<GetStaffQuery, GetStaffQueryVariables>(query);

  let list = data?.staffMemberCollection?.items && [
    ...data?.staffMemberCollection?.items,
  ];
  let sortedList = list?.sort((a, b) => (a?.order || 0) - (b?.order || 0));
  return (
    <div className="staffContainer">
      {data &&
        sortedList &&
        sortedList.map((staff, key: number) => {
          return (
            <div className="staffMember" key={key}>
              <div
                className="staffImage"
                style={{
                  backgroundImage: `url(${staff?.image?.url})`,
                }}
              ></div>
              <div className="staffName">{staff?.name}</div>
              <div className="staffDescription">
                <RichTextRender content={staff?.description?.json} />
              </div>
            </div>
          );
        })}
    </div>
  );
}
