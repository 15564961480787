import { ReactElement, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { GetProductsQuery, GetProductsQueryVariables } from "../../schema";
import ProductModal from "./ProductModal";

export default function Products(): ReactElement {
  const [modal, setModal] = useState<any>();

  const query = gql`
    query getProducts {
      productCategoriesCollection(limit: 30) {
        items {
          title
          image {
            url
          }
          categoriesCollection(limit: 10) {
            items {
              title
              description {
                json
              }
              productsCollection(limit: 10) {
                items {
                  title
                  image {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { data } = useQuery<GetProductsQuery, GetProductsQueryVariables>(query);

  return (
    <>
      <div id="productContainer">
        {data &&
          data.productCategoriesCollection?.items.map((brand, key: number) => {
            return (
              <div
                className="companyContainer"
                onClick={() => {
                  setModal(brand);
                }}
                key={key}
              >
                <div
                  className="companyLogo"
                  style={{
                    backgroundImage: `url(${brand?.image?.url})`,
                  }}
                ></div>
                <div className="panel-body">{brand?.title}</div>
              </div>
            );
          })}
      </div>

      {modal && (
        <ProductModal
          visible={!!modal}
          data={modal}
          onCancel={() => {
            setModal(undefined);
          }}
        ></ProductModal>
      )}
    </>
  );
}
