import { ReactElement, useState } from "react";
import { Modal, Table } from "antd";
import { gql, useQuery } from "@apollo/client";
import { GetTreatmentsQuery, GetTreatmentsQueryVariables } from "../../schema";

export default function Treatments(): ReactElement {
  const query = gql`
    query getTreatments {
      treatmentsCollection {
        items {
          sys {
            id
          }
          order
          title
          image {
            url
          }
          treatmentsCollection {
            items {
              time
              title
              price
            }
          }
        }
      }
    }
  `;

  let { data } = useQuery<GetTreatmentsQuery, GetTreatmentsQueryVariables>(
    query
  );

  let list = data?.treatmentsCollection?.items && [
    ...data?.treatmentsCollection?.items,
  ];

  const [modal, setModal] = useState<any>();

  return (
    <>
      <div id="treatmentContainer">
        {data &&
          list &&
          list
            .sort((a: any, b: any) => (a?.order || 0) - (b?.order || 0))
            .map((treatment, key: number) => {
              return (
                <div
                  onClick={() => setModal(treatment)}
                  key={key}
                  className="panel category litunogplokkun"
                >
                  <div
                    className="categoryImage"
                    style={{
                      backgroundImage: `url(${treatment?.image?.url})`,
                    }}
                  ></div>
                  <div className="panel-body">{treatment?.title}</div>
                </div>
              );
            })}
      </div>
      {modal && (
        <Modal
          title={modal.title}
          onCancel={() => {
            setModal(null);
          }}
          footer={null}
          visible={!!modal}
        >
          <Table
            size="small"
            pagination={false}
            columns={[
              {
                title: "Nafn",
                dataIndex: "title",
                key: "title",
              },
              {
                title: "Verð",
                dataIndex: "price",
                key: "price",
              },
              {
                title: "Tími",
                dataIndex: "time",
                key: "time",
              },
            ]}
            dataSource={modal.treatmentsCollection.items.map(
              (t: any, key: number) => {
                return {
                  key: key,
                  title: t.title,
                  price: t.price,
                  time: t.time,
                };
              }
            )}
          />
        </Modal>
      )}
    </>
  );
}
