import React from "react";
import { Modal, Tabs } from "antd";
import { ProductCategories } from "../../schema";

const { TabPane } = Tabs;

type Props = {
  visible: boolean;
  onCancel: () => void;
  data: ProductCategories;
};

export default function ProductModal(props: Props) {
  return (
    <Modal
      visible={props.visible}
      title={props.data.title}
      onCancel={() => {
        props.onCancel();
      }}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          style={{ maxWidth: 200 }}
          src={props.data?.image?.url || ""}
          alt="Company logo"
        />

        <Tabs
          style={{
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {props.data?.categoriesCollection?.items.map(
            (category, key: number) => {
              return (
                <TabPane tab={category?.title} key={key}>
                  <div className="productParent">
                    {category?.productsCollection?.items.map((product, i) => {
                      return (
                        <div
                          className="product"
                          key={i}
                          style={{ textAlign: "center" }}
                        >
                          <div
                            className="productImage"
                            style={{
                              backgroundImage: `url(${product?.image?.url})`,
                            }}
                          ></div>
                          <div className="productName" style={{ padding: 10 }}>
                            {product?.title}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </TabPane>
              );
            }
          )}
        </Tabs>
      </div>
    </Modal>
  );
}
