import { useState, useRef } from "react";
import "./App.scss";
import "antd/dist/antd.css";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import Treatments from "./components/Treatments/Treatments";
import Products from "./components/Products/Products";
import Staff from "./components/Staff/Staff";

const {
  REACT_APP_CONTENTFUL_SPACE_ID: SPACE_ID,
  REACT_APP_CONTENTFUL_ENVIRONMENT: ENVIRONMENT,
  REACT_APP_CONTENTFUL_API_KEY: API_KEY,
} = process.env;

function App() {
  const apolloClient = new ApolloClient({
    uri: `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${ENVIRONMENT}`,
    headers: {
      Authorization: `Bearer ${API_KEY}`,
    },
    cache: new InMemoryCache(),
  });

  const [navbarOpen, setNavbarOpen] = useState(false);

  const treatments = useRef<null | HTMLDivElement>(null);
  const products = useRef<null | HTMLDivElement>(null);
  const staff = useRef<null | HTMLDivElement>(null);
  const contact = useRef<null | HTMLDivElement>(null);

  return (
    <ApolloProvider client={apolloClient}>
      <header>
        <nav className="navbar navbar-default anonymousNavbar">
          <div className="container">
            <div className="navbar-header">
              <button
                onClick={() => {
                  setNavbarOpen(!navbarOpen);
                }}
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#anonymousHeaderLinks"
                aria-expanded="false"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <div className="mainLogo clearfix">
                <a href="/">
                  <img
                    src="/logo_light.png"
                    className="brandLogo"
                    alt="Brand logo"
                  />
                </a>
              </div>
            </div>

            <div
              className={`collapse navbar-collapse ${navbarOpen ? "open" : ""}`}
              id="anonymousHeaderLinks"
            >
              <ul className="nav navbar-nav navbar-right">
                <div
                  onClick={() => {
                    setNavbarOpen(false);
                    treatments?.current?.scrollIntoView();
                  }}
                  className="btn btn-default btn-clear"
                >
                  <i className="fa fa-diamond"></i> Meðferðir
                </div>
                <div
                  onClick={() => {
                    setNavbarOpen(false);
                    products?.current?.scrollIntoView();
                  }}
                  className="btn btn-default btn-clear"
                >
                  <i className="fa fa-cube"></i> Vörur
                </div>
                <div
                  onClick={() => {
                    setNavbarOpen(false);
                    staff?.current?.scrollIntoView();
                  }}
                  className="btn btn-default btn-clear"
                >
                  <i className="fa fa-users"></i> Starfsfólk
                </div>
                <div
                  onClick={() => {
                    setNavbarOpen(false);
                    contact?.current?.scrollIntoView();
                  }}
                  className="btn btn-default btn-clear"
                >
                  <i className="fa fa-phone"></i> Hafa samband
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div id="frontpage">
        <div id="featured" style={{ backgroundImage: "url(/cover.jpeg)" }}>
          <div className="featuredContent">
            <div className="text" id="title">
              Snyrtistofan Bella
            </div>
            <div className="text" id="subTitle">
              "Það er kominn tími til að slaka á þegar þú hefur ekki tíma til að
              slaka á"
            </div>
          </div>
        </div>

        <section ref={treatments} id="medferdir">
          <svg
            className="curve"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 706 40"
          >
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  className="cls-1"
                  d="M706,40H0V0S148,14,353,14C568,14,706,0,706,0Z"
                />
              </g>
            </g>
          </svg>
          <div className="overlay"></div>
          <h2 className="title">Meðferðir</h2>

          <Treatments></Treatments>
        </section>

        <section ref={products} id="products" className="clearfix gray">
          <svg
            className="curve"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 706 40"
          >
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  className="cls-1"
                  d="M706,40H0V0S148,14,353,14C568,14,706,0,706,0Z"
                />
              </g>
            </g>
          </svg>

          <h2 className="title">Vörur</h2>
          <Products></Products>
        </section>

        <section ref={staff} id="employees">
          <div className="container">
            <svg
              className="curve"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 706 40"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path
                    className="cls-1"
                    d="M706,40H0V0S148,14,353,14C568,14,706,0,706,0Z"
                  />
                </g>
              </g>
            </svg>
            <h2 className="title">Starfsfólk</h2>
            <Staff></Staff>
          </div>
        </section>

        <section ref={contact} id="contact" className="gray">
          <div className="container">
            <svg
              className="curve"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 706 40"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path
                    className="cls-1"
                    d="M706,40H0V0S148,14,353,14C568,14,706,0,706,0Z"
                  />
                </g>
              </g>
            </svg>
            <h2 className="title">Hafa samband</h2>
            <div className="content">
              <div className="row">
                <div className="col-sm-6">
                  <a className="panel text-center blokk" href="tel:7662222">
                    <div className="panel-body">
                      <h1>
                        <i className="fa fa-phone"></i>
                      </h1>
                      <h3 style={{ textAlign: "center" }}>Símanúmer</h3>
                      <p>766 2222</p>
                    </div>
                  </a>
                </div>

                <div className="col-sm-6">
                  <a
                    className="panel text-center blokk"
                    href="mailto:snyrtistofanbella@gmail.com"
                  >
                    <div className="panel-body">
                      <h1>
                        <i className="fa fa-envelope"></i>
                      </h1>
                      <h3 style={{ textAlign: "center" }}>Tölvupóstur</h3>
                      <p>snyrtistofanbella@gmail.com</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="footerContainer">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-2">
                <div className="footerLogo">
                  <img alt="Logo" src="/logo_light.png" />
                </div>
              </div>

              <div className="col-xs-12 col-sm-8 text-center">
                <a
                  href="https://facebook.com/snyrtistofanbella"
                  target="_blank"
                  className="btn btn-clear"
                  rel="noreferrer"
                >
                  Við erum á facebook <i className="fa fa-facebook-square"></i>
                </a>
              </div>

              {/* <div className="col-xs-12 col-sm-2">
                <img
                  alt="Creditcards"
                  className="creditCards"
                  src="https://tweecode.com/icloud/images/fjaroflun/15991l1485459632.png"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="footerBot text-center">
        <span className="item">Snyrtistofan Bella ehf</span>

        <span className="item">Hótel Saga - Hagatorg 1</span>

        <span className="item">Sími: 766-2222</span>
        <span className="item">
          snyrtistofanbella<span className="atmerki">@</span>gmail.com
        </span>

        <span className="item">Kennitala: 480916-1150</span>

        <span className="item">VSK-númer 125808</span>
      </div>
    </ApolloProvider>
  );
}

export default App;
